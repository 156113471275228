import Layout from '../components/layout'
import './Blog.scss'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { Link } from 'react-router-dom'

function BlogLanding(props) {
    return (
        <Layout {...props} className="blog">
            <div className='main-content'>
                <h1 className='top-fix'><strong>Our Latest Blogs</strong></h1>
                <div className='previewbox'>
                    <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\FilesWorkForPrint.jpg' alt='Files for Print'></img><br /><br />
                    <h2 className='previewtitle'>Making your file ready for Print</h2>
                    <p className='previewtext'>Printing your files may seem like a simple task, but there are a few important steps which you need to take note of to ensure a smooth and successful outcome...<br /><br />
                        <Link to={urlGenerator.get({page:'HowToEnsureYourDesignFileReallyWorksForPrint'})}>
                            <span className="link"  dangerouslySetInnerHTML={{ __html: "Read more »" }}></span>
                            </Link>
                    </p>
                </div>
                <div className='previewbox'>
                    <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\PageCounts.jpg' alt='Pages and Page Counts'></img><br /><br />
                    <h2 className='previewtitle'>Pages in Printing and Page Counts</h2>
                    <p className='previewtext'>Whenever you're involved in any kind of printing that involves more than 4 pages you will be looking to add folios. Whether its book or magazine publishing...<br /><br />
                    <Link to={urlGenerator.get({page:'PagesLeavesAndPageCounts'})}>
                            <span className="link"  dangerouslySetInnerHTML={{ __html: "Read more »" }}></span>
                            </Link>
                    </p>
                </div>
                <div className='previewbox'>
                    <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\DesignForPrint.jpg' alt='Design for Print'></img><br /><br />
                    <h2 className='previewtitle'>How to Design for Print</h2>
                    <p className='previewtext'>Designers are creative masters often thinking outside the box when designing. What they see and create on screen does not always translate onto paper...<br /><br />
                    <Link to={urlGenerator.get({page:'DesignForPrint'})}>
                            <span className="link"  dangerouslySetInnerHTML={{ __html: "Read more »" }}></span>
                            </Link>
                    </p>
                </div>
                <div className='previewbox'>
                    <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\PaperSizes.jpg' alt='Paper Sizes'></img><br /><br />
                    <h2 className='previewtitle'>Paper Sizes in Print</h2>
                    <p className='previewtext'>After thinking that you need to produce a printed piece the very first consideration should be “what size?” Depending on the country you live in, standard sizes...<br /><br />
                    <Link to={urlGenerator.get({page:'PaperSizes'})}>
                            <span className="link"  dangerouslySetInnerHTML={{ __html: "Read more »" }}></span>
                            </Link>
                    </p>
                </div>
                <div className='previewbox'>
                    <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\UsingBlack.jpg' alt='Using Black'></img><br /><br />
                    <h2 className='previewtitle'>Using Black Text in Print</h2>
                    <p className='previewtext'>Black is used on every page of almost every printed item you find; whether in the type/text, graphics or a background colour. So, what’s the deal with Black?<br /><br />
                    <Link to={urlGenerator.get({page:'BlackInPrint'})}>
                            <span className="link"  dangerouslySetInnerHTML={{ __html: "Read more »" }}></span>
                            </Link>
                    </p>
                </div>
                <div className='previewbox'>
                    <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\lithoPrint.jpg' alt='Litho Printing'></img><br /><br />
                    <h2 className='previewtitle'>What is Litho Printing?</h2>
                    <p className='previewtext'>Also referred to as offset printing, litho printing is an age-old printing technique that today delivers cost-effective large-batch jobs with superb colour...<br /><br />
                    <Link to={urlGenerator.get({page:'WhatIsLithoPrint'})}>
                            <span className="link"  dangerouslySetInnerHTML={{ __html: "Read more »" }}></span>
                            </Link>
                    </p>
                </div>
                <div className='previewbox'>
                    <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\SaddleStitch.jpg' alt='Saddle Stitching'></img><br /><br />
                    <h2 className='previewtitle'>What is Saddle Stitching?</h2>
                    <p className='previewtext'>Saddle stitching is a versatile and commonly used bookbinding technique that involves binding pages together by stapling them along the fold in the center spine...<br /><br />
                    <Link to={urlGenerator.get({page:'SaddleStitching'})}>
                            <span className="link"  dangerouslySetInnerHTML={{ __html: "Read more »" }}></span>
                            </Link>
                    </p>
                </div>
            </div>
        </Layout>
    )
}
  
export default BlogLanding
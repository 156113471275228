import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import Layout from '../components/layout'
import './ContactUs.scss'
       /* BRAAM add feedback*/
       import FeedBack from 'react-feedback-popup';


const ContactUs = (props) => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('Remata', 'template_rir3hlx', form.current, 'JUJM7eBoTA6mG5_qM')
        .then((result) => {
            console.log(result.text);
            console.log("message sent");
           // alert('Message sent successfully! We will get back to you asap.')
           window.location.replace('https://onlineprintshop.remata.co.za/en-ZA/ThankYou/');
        }, (error) => {
            console.log(error.text);
            alert(`${error.text} happened`);
            document.getElementById(form.current).reset();
        });
    };
    return (
      <Layout {...props} className="contact-us">
        <div className="container-fluid">
                 {/* BRAAM add feedback*/}
 <FeedBack
				style={{zIndex:'2', marginLeft:'20px', position:'fixed'}}
				position="left"
				numberOfStars={5}
				headerText="Hello"
				bodyText="Please give us your feedback or recommendations"
				buttonText="Give us Feedback"
				handleClose={() => console.log("handleclose")}
        handleSubmit={(data) => 



            emailjs.send('Remata', 'template_rir3hlx', {
             message: JSON.stringify(data)}, 'JUJM7eBoTA6mG5_qM') .then((result) => {
              console.log(result.text);
              console.log("message sent");
             // alert('Message sent successfully! We will get back to you asap.')
             window.location.replace('https://onlineprintshop.remata.co.za/en-ZA/ThankYou/');
          }, (error) => {
              console.log(error.text);
              alert(`${error.text} happened`);
  
          })


        }
     
        handleButtonClick={() => console.log("handleButtonClick")}
      
      />
          
          <div className="row top-fix"> {/* G: Fix for top banner */}
            <div className="col-sm-5 contact-float-L"> {/* G: Float Application */}
            <h1>CONTACT US</h1> 
              <p>To get in touch with us, please complete the form below and <br />
                we will get back to you asap.</p>
              <p>Remata Print and Communication<br /> 
              International Business Gateway Park<br />
              Cnr 6th and New Road, Midrand, Gauteng, South Africa</p>
              <p>Tel: <a href='tel:+27 11 848 0000'>+27 11 848 0000</a><br />
              Fax: <a href='fax:+27 11 848 0036'>+27 11 848 0036</a><br />
              Email: <a href='mailto:onlineprintshop@remata.co.za'>onlineprintshop@remata.co.za</a></p>
              <form ref={form} onSubmit={sendEmail}>
              <label>Name</label><br />
              <input className='field-width' type="text" name="user_name" required/><br /> {/* G: Input Styling */}
              <label>Email</label><br />
              <input className='field-width' type="email" name="user_email" required/><br /> {/* G: Input Styling */}
              <label>Telephone number</label><br />
              <input className='field-width' type="tel" name="user_tel" required/><br /> {/* G: Input Styling */}
              <label>Message</label><br />
              <textarea className='field-width' name="message" /><br /> {/* G: Input Styling */}
              <input type="submit" value="Send" /><br />
              </form>
            </div>
          
          <div className='contact-float-R'> {/* G: Float Application + map insertion */}
            <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3586.6786436267325!2d28.11905631545007!3d-25.978577661148474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e956e25099b9e57%3A0x30a19dceae583c0d!2sRemata+Communications+%26+Printers!5e0!3m2!1sen!2sza!4v1516601111084"
            width="100%"
            height={480}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen=""/>
          </div>
        </div>
        </div>
      </Layout>
    )
}
  
export default ContactUs
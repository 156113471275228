import React, { useEffect } from 'react'
import Legacy from './Legacy'
import {UStoreProvider} from '@ustore/core'
import emailjs from '@emailjs/browser'
// Braam add slot
import { Slot } from '$core-components'

// Braam add email gclid
// emailjs.send('Remata', 'template_rir3hlx', {
 //  message: JSON.stringify(Slot.gclid)}, 'JUJM7eBoTA6mG5_qM') .then((result) => {
 //   console.log(result.text);
 //   console.log("message sent");
  // alert('Message sent successfully! We will get back to you asap.')

//}, (error) => {
 //  console.log(error.text);
  // alert(`${error.text} happened`);

//})

const CheckoutComplete  = (props) => {
  useEffect(() => {
    UStoreProvider.state.store.loadCartItemsCount()
  }, [])

  return <Legacy {...props}/>
  
}


export default CheckoutComplete

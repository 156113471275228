import React, { Component } from "react"
import Slider from "react-animated-slider";
import "./AnimatedSlider.scss";
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { Link } from 'react-router-dom'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import { t } from '$themelocalization'

class AnimatedSlider extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          defaultURL: urlGenerator.get({page: 'home'})
        }
    
    }
    static getDerivedStateFromProps(props, state) {
        if (!(props.state && props.customState)) {
        return null
        }
        
        const { categories } = props.customState
        //NOTE: this is not supported in SSR
        if (categories && categories.length) {
            const { FriendlyID, Name } = categories[0]
            const defaultURL = urlGenerator.get({ page: 'category', id: FriendlyID, name: decodeStringForURL(Name) })
            return { 'defaultURL': defaultURL }
        }
        return null
    }
    render() {
        const promotionItemImageUrl = getVariableValue('--homepage-carousel-slide-1-image', require(`$assets/images/POC_banner_image1.png`), true)
        const promotionItemButtonUrl = getVariableValue('--homepage-carousel-slide-1-button-url', this.state.defaultURL, false, true);
        const promotionItemTitle = getVariableValue('--homepage-carousel-slide-1-main-text', t('slide1Heading'))
        const promotionItemSubtitle = getVariableValue('--homepage-carousel-slide-1-sub-text',  t('slide1Subtitle'))
        const promotionItemButtonText = getVariableValue('--homepage-carousel-slide-1-button-text',  t('slide1ButtonText'))
        const promotionItemImageUrl2 = getVariableValue('--homepage-carousel-slide-2-image', require(`$assets/images/POC_banner_image2.png`), true)
        const promotionItemButtonUrl2 = getVariableValue('--homepage-carousel-slide-2-button-url', this.state.defaultURL, false, true);
        const promotionItemTitle2 = getVariableValue('--homepage-carousel-slide-2-main-text', t('slide2Heading'))
        const promotionItemSubtitle2 = getVariableValue('--homepage-carousel-slide-2-sub-text', t('slide2Subtitle'))
        const promotionItemButtonText2 = getVariableValue('--homepage-carousel-slide-2-button-text', t('slide2ButtonText'))
        const promotionItemImageUrl3 = getVariableValue('--homepage-carousel-slide-3-image', require(`$assets/images/POC_banner_image3.png`), true)
        const promotionItemButtonUrl3 = getVariableValue('--homepage-carousel-slide-3-button-url', this.state.defaultURL, false, true);
        const promotionItemTitle3 = getVariableValue('--homepage-carousel-slide-3-main-text', t('slide3Heading'))
        const promotionItemSubtitle3 = getVariableValue('--homepage-carousel-slide-3-sub-text', t('slide3Subtitle'))
        const promotionItemButtonText3 = getVariableValue('--homepage-carousel-slide-3-button-text', t('slide3ButtonText'))
        const promotionItemImageUrl4 = getVariableValue('--homepage-carousel-slide-4-image', require(`$assets/images/Specials-web banners_Inkjet Posters_V1-01.jpg`), true)
        const promotionItemButtonUrl4 = getVariableValue('--homepage-carousel-slide-4-button-url', this.state.defaultURL, false, true);
        const promotionItemTitle4 = getVariableValue('--homepage-carousel-slide-4-main-text', t('slide4Heading'))
        const promotionItemSubtitle4 = getVariableValue('--homepage-carousel-slide-4-sub-text', t('slide4Subtitle'))
        const promotionItemButtonText4 = getVariableValue('--homepage-carousel-slide-4-button-text', t('slide4ButtonText'))

        const content = [
            {
                style: 'banner1',
                title: promotionItemTitle, 
                description: promotionItemSubtitle,
                button: promotionItemButtonText,
                url: promotionItemButtonUrl,
                image: promotionItemImageUrl
            },
            {
                style: 'banner2',
                title: promotionItemTitle2, 
                description: promotionItemSubtitle2,
                button: promotionItemButtonText2,
                url: promotionItemButtonUrl2,
                image: promotionItemImageUrl2
            },
            {
                style: 'banner3',
                title: promotionItemTitle3, 
                description: promotionItemSubtitle3,
                button: promotionItemButtonText3,
                url: promotionItemButtonUrl3,
                image: promotionItemImageUrl3
            },
            {
                style: 'banner4',
                title: promotionItemTitle4, 
                description: promotionItemSubtitle4,
                button: promotionItemButtonText4,
                url: promotionItemButtonUrl4,
                image: promotionItemImageUrl4
            }
        ];

        return (
            <div className="animated-slider">
                <Slider className="slider-wrapper" autoplay={3000}>
                    {content.map((item, index) => (
                        <div
                            key={index}
                            className="slider-content"
                            style={{ background: `url('${item.image}') no-repeat center center` }}
                        >
                            <div className="inner">
                                <h1 className={item.style}>{item.title}</h1>
                                <p className={item.style}>{item.description}</p>
                                {item.url.substring(0,4).toLowerCase() === 'http'
                                    ? <a className="button button-primary" href={item.url}>{item.button}</a>
                                    : <Link to={item.url} className="button button-primary">{item.button}</Link>}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}
export default AnimatedSlider

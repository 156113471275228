import Layout from '../components/layout'
import './AboutUs.scss'
       /* BRAAM add feedback*/
import FeedBack from 'react-feedback-popup';
import emailjs from '@emailjs/browser'
/*import TagManager from 'react-gtm-module'

TagManager.initialize({gtmId : 'GTM-T5JWR4D'})
const tagManagerArgs = {
  dataLayer: {
      ecommerce: null,
      event: 'view_item_list',
      item_id: "1",
      item_name: "Business Cards",
      price: "300.95",
      quantity: "250"
  },
    dataLayerName: 'BraamTestGTMItemDataLayer'
}*/




const AboutUs = (props) => {
  /* G: Braam add tag manager data layer 
  TagManager.dataLayer(tagManagerArgs);*/

    return (
      <Layout {...props} className="about-us">
         <FeedBack
				style={{zIndex:'2', marginLeft:'20px', position:'fixed'}}
				position="left"
				numberOfStars={5}
				headerText="Hello"
				bodyText="Please give us your feedback or recommendations"
				buttonText="Give us Feedback"
				handleClose={() => console.log("handleclose")}
        handleSubmit={(data) => 



            emailjs.send('Remata', 'template_rir3hlx', {
             message: JSON.stringify(data)}, 'JUJM7eBoTA6mG5_qM') .then((result) => {
              console.log(result.text);
              console.log("message sent");
             // alert('Message sent successfully! We will get back to you asap.')
             window.location.replace('https://onlineprintshop.remata.co.za/en-ZA/ThankYou/');
          }, (error) => {
              console.log(error.text);
              alert(`${error.text} happened`);

          })


        }
     
        handleButtonClick={() => console.log("handleButtonClick")}
      
      />
        <div className='main-content'>
            <h1 className='top-fix'>About Us</h1> {/* G: Fix for top banner */}
            <h2>Our unique history continues into the future</h2><br />
            <p>Starting in 1970, Remata moved into the limelight in 1987 with the launch of the ﬁrst Desktop Publishing Bureau in South Africa. Merging what seemed to be opposing ﬁelds
              – computers and print – was not only a challenge, but an opportunity that was exciting  and could by no means be ignored.</p>
            <p>After establishing DTP in South Africa, Remata took the next step and began doing again what others were frightened to do – digitizing scans so that it could be placed 
              directly into a computer ﬁle, to allow fast turnaround times and extremely high quality, ﬁrst generation page layouts. Even that challenge was not enough, so, in 1996 an 
              Agfa Chromapress was brought into the family allowing true full colour digital short run printing to be launched in South Africa.</p>
            <p>It was only natural for Remata to maintain their growth by adding in higher quality, short to long run presses. This led to them being one of the ﬁrst printing 
              establishments in South Africa to run the Heidelberg DI 46 and then the DI 74’s. Not stopping there, Remata added stitching gang lines, die cutting, high speed 
              laminators, collating, tape and perfect binding machines to complement their expanding ﬁnishing department. This enabled quicker turnaround times and full control 
              of client deadlines. Skilled hand ﬁnishers were employed to ensure quality hand ﬁnishing on jobs that were done on site.</p>
            <p>Having focused on improving the Litho print side of the business, attention was then turned to the Digital Printing departments where a Heidelberg Nexpress was added 
              and the Indigo press was upgraded allowing for Pantone matching and high quality short run printing. These presses have also been opening up the variable data market 
              and personalization markets and have been a strong compliment to the Litho side of the business.</p>
            <p>In 2008 an additional 5 colour CD Heidelberg press was added to enable more packaging and high speed litho print. Supplemental to this was Inkzone technology which 
              helped maintain consistent colour quality that the discerning South African market was demanding.</p>
            <p>With changes happening so rapidly it wasn’t long before more high-end Indigo presses were added streamlining technology and living up to the high standards of 
              consistency and delivery in the digital department. Then UV flatbed printers and quality die cutting machines were added giving clients more options under one roof.</p>
            <p>To enable the short run market to have accurate spot Uv without having to turn to conventional means, a Scodix machine was installed that enabled work directly off 
              digital and litho presses to have effects added with precision providing more value for printed items.</p>
            <p>To ensure Remata continued as a pioneer and leader in the Industry, a Cross Media Division, utilizing global leading software, was added giving clients options to 
              create personalised print items with multiple ﬁelds giving Companies a high ROI for their print spend. The creation of fully customized Web Stores allowed for larger 
              Companies to manage print electronically giving consistent Brand identity and greatly reducing the normal ordering processes. Digital media campaigns  were upgraded 
              to then include forms of print that completed the communication circle of life proving much higher ROI’s.</p>
            <p>Early 2016, a Point Of Sale Service  was added opening up a whole new space for clients to oﬀer more than ever before allowing them to grow their businesses together 
              with Remata, continuing in the legacy as a constant market disruptor, installed the latest Komori HUV litho press, replacing the older conventional presses. The print 
              size oﬀered was also increased to accommodate A1 instead of the normal B2 size. This spec allows for larger runs, faster print speeds and for drip-oﬀ eﬀects to be 
              created during the print run and in many cases reducing the need for outside ﬁnishing like spot UV.</p>
            <p>It was then time to grow into the training manual and the book markets allowing for low to medium runs to be done cost effectively and quickly. To this end inkjet 
              presses were added as well as high speed punching machines to facilitate the quick turnaround times associated with this market sector.</p>
            <p>Remata, seeing the future of online shopping playing a huge role in the future, developed an Online Shop allowing clients to buy and connect directly as they would 
              in a normal on line retail environment. The Online Shop was taken to a whole new level in 2024 when the team moved the store onto a much more stable and upgradable 
              platform and softwre working together with their global partner to ensure visions aligned. Remata is only on phase 1 this exciting journey so its worth keeping an eye 
              on how quickly things will develop during 2024 and 2025 onwards.</p>  
            <p>In early 2023 Remata became part of the Formeset Group which encompasses quality companies like ABC Press, Lexlines and Creda Communications which already have a 
              good footprint in litho large run quality bookwork, digital offerings for the print of short runs books and all forms of continuous stationery. This partnership has 
              helped broaden Remata’s product offering to its existing clients.</p>     
            <p>Although Remata places a strong emphasis on equipment and technology it also has a big heart for its people. Much time and money is spent on taking those with 
              potential and empowering staﬀ with more skills. People together with technology are Remata’s focus.</p>
            <p>Remata’s thirst for breaking industry moulds and taking printing, packaging and point of sale out of the box has secured them their place in tomorrow’s world. 
              Creative people create solutions and products that touch the ever-changing needs of companies and markets. Remata is ﬁlled with passionate, creative people that have 
              grown the company into one of the leaders in the South African printing industry.</p>    
            <p>So whether its FSU’s, Brochures, Folders, Magazines, Coffee Table Publications, Books, Training Manuals, personalized documents in high end print quality or the humble 
              Flyer and Business Card, Remata is an all-rounder continuing to expand its services to serve their most discerning and forward thinking clients.</p>
            <p>Remata’s philosophy is simple – <strong>‘work hard, discover new avenues for communication, and open the sails to allow the winds of change to move you.’</strong></p>
            <p>Remata is a <a target='_blank' href='#'>BEE Level 2</a> value adding supplier.</p>
            <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\Reception.jpg' alt='Remata Reception'></img>     
        </div>
     
      </Layout>
    )
}
  
export default AboutUs
import Layout from '../components/layout'
import './ContactUs.scss'



const ThankYou = (props) => {
   
    return (
      <Layout {...props} className="contact-us">
        <div className="container-fluid">
          
          <div className="row top-fix"> {/* G: Fix for top banner */}
            <div className="col-sm-5 contact-float-L"> {/* G: Float Application */}
            <h1>THANK YOU</h1> 
              <p>Thank you for your submission, we will be in touch.</p>
              <p>Remata Print and Communication<br /> 
              International Business Gateway Park<br />
              Cnr 6th and New Road, Midrand, Gauteng, South Africa</p>
              <p>Tel: +27 11 848 0000<br />
              Fax: +27 11 848 0036<br />
              Email: <a href='mailto:onlineprintshop@remata.co.za'>onlineprintshop@remata.co.za</a></p>
            </div>
          <div className='contact-float-R'> {/* G: Float Application + map insertion */}
            <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3586.6786436267325!2d28.11905631545007!3d-25.978577661148474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e956e25099b9e57%3A0x30a19dceae583c0d!2sRemata+Communications+%26+Printers!5e0!3m2!1sen!2sza!4v1516601111084"
            width="100%"
            height={480}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen=""/>
          </div>
          </div>
        </div>
      </Layout>
    )
}
  
export default ThankYou
import React, { useRef } from 'react'
import Layout from '../components/layout'
import './Policy.scss'

const PolicyProofing = (props) => {
    return (
      <Layout {...props} className="policy">
        <div className='main-content'>
            <h1 className='top-fix'>Proofs / Hard Copy Policy</h1>
            <p>10.1. If requested, an online digital proof will be made available or emailed for your review after we have received your files for print.</p>
            <p>10.2. Jobs with proof requests will not be sent to press without the Customers approval unless the proof approval is explicitly waived. 
                Remata not liable for delays in the order caused by the Customers non-approval of the proof or waiting for the proof to be approved.</p>
            <p>10.3. <strong>Important:</strong> An online proof is not an accurate colour reproduction of the final printed piece. It is the final opportunity for the Customer 
                to check all aspects of the job including but not limited to: size, layout, bleeds, crops, ensuring high resolution images are used, ensuring 
                RGB is converted to CMYK, and final text. Electronic proofs do not show transparency and overprint issues nor do they show colour change from RGB to CMYK.</p>
            <p>10.4. The proof must be treated as independent from the original submitted file and thoroughly reviewed prior to approval. It should be checked against the 
                original file for possible errors in size, layout, copy, spacing, punctuation or image placement. The Customer is fully responsible for all that is contained in 
                the final approved proof.</p>
            <p>10.5. Delivered plotter proofs show reasonable likeness to the final printed pieces. Delivered plotter proofs are printed on a bond paper using inkjet inks 
                which differs from the actual stock or substrate and the inks used of the final product.</p>
            <p>10.6. FOGRA proofs are recommended for colour-critical artwork and must be requested by the Customer when the print order is made. FOGRA’s are only 95% accurate 
                on coated stocks/substrates and 80% accurate on uncoated stocks/substrates.</p>
            <p>10.7. The Customer must ensure they have read and understood their responsibilities to check all aspects of the job including size, number of pages and cover, 
                page order, all content and that the colour is a close representation of what they require.</p>
            <p>10.8. Remata’s prepping department does basic checks on all submitted artwork files before printing, but the customer is still 100% responsible for the accuracy 
                of the print-ready artwork files submitted, and it is the responsibility of the Customer to check all aspects and to proofread all files carefully before 
                submitting any files for print. Remata is not responsible for any issues as to orientation or alignment of the pages of any submitted artwork.</p>
            <p>10.9. Any additional proofs above an email proof done, will be charged for. The request for a delivered plotter proof will extend the amount of time needed 
                to complete the job. The job can only proceed to print once the client has signed and returned the signed off hard copy proof. In the case of an emailed 
                proof Remata will only proceed to print once an email approval is received.</p> 
            <p>10.10. Where Remata has produced a proof for Customer, Customer acknowledges and accepts that such plotter or digital proof is merely illustrative of the final 
                product to be produced by Remata and Remata shall have no liability to Customer for slight variations in the final Product from the proof supplied.</p> 
            <p>10.11. In the event that the Customer notifies Remata that it wishes to proceed with the order without correcting any defects in the Artwork, the Customer does 
                so at its own risk. Once approved by the Customer, Remata holds no responsibility whatsoever for any errors incurred.</p> 
            <p>10.12. If additional costs arise due to the inaccuracy of the Artwork, these will be borne by the Customer.</p> 
            <p>10.13. After the order is placed, the Customer shall only be entitled to make changes to the order provided the customer remains liable for any additional 
                costs incurred by Remata in making such changes.</p> 
            <p>10.14.  Plotter proofs or hardcopy digital proofs will not have any special finishing on them that the final job will have.</p> 
            <p>10.15. When proofs larger than what can be produced on Remata’s Indigo A3 digital presses are required,  these are printed on larger inkjet plotters on bond 
                paper but must still be checked in terms of all other aspects (refer to point 10.3) to ensure its correct before signing off to go to print. Colour accuracy 
                form plotter proofs are only 85% for coated and even 70% for uncoated stocks.</p> 
            <p>10.16. For proofs that are a size that can be run on the A3 digital presses, a hardcopy is printed will be printed on the Indigo to resemble as close as 
                possible the final product in terms of colour and stock. Colour accuracy of this proof is approx. 90% for coated and 85% for uncoated stocks.</p> 
            <p>It is vital for the Customer to check with each product online the possible addition of any conditions in terms of a hard copy proof. When the Customer is on 
                the specifications of the product page of the Remata onlineprintshop, the Customer must simply click on the “Hardcopy proof required” which has a dotted 
                underline and more detail will pop up. The Customer must read this to avoid any misunderstanding.</p>     
        </div>
      </Layout>
    )
}
  
export default PolicyProofing
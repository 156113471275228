import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Testimonials.scss";

export default class Testimonials extends Component {
  render() {
    return (
      <div className="testimonial-slider">
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={3000}
      >
        <div>
          <div className="myCarousel1">
            <h3>Andre</h3>

            <p>
            Thank you for a fantastic job. You exceeded our expectation.
If I come across a person that needs printing work there is only one place i will send them to. There is only one name. REMATA

            </p>
          </div>
        </div>

        <div>
          <div className="myCarousel1">
            <h3>TILT INTERACTIVE</h3>

            <p>
            Excellent service and quality as well as ease of use, thank you!
            </p>
          </div>
        </div>

        <div>
          <div className="myCarousel1">
            <h3>Aileen</h3>

            <p>
            I cannot tell you how much it means to me that for this tiny job, I was treated so kindly, especially since I know nothing about printing!
            </p>
          </div>
        </div>

        <div>
          <div className="myCarousel1">
            <h3>Petra</h3>

            <p>
            I received the postcards yesterday..and I can't stop looking at them! They are gorgeous - well done! Very good quality printing, thank you so much!
            </p>
          </div>
        </div>


        <div>
          <div className="myCarousel1">
            <h3>Mickey</h3>

            <p>
            We just received our year books! OMW!!! These are exceptional!!! I literally have a lump in my throat! They came out better than ever imagined!!!
            </p>
          </div>
        </div>


        <div>
          <div className="myCarousel1">
            <h3>Rudy</h3>

            <p>
            Could not be happier with the quality of the product received.
            </p>
          </div>
        </div>


        <div>
          <div className="myCarousel1">
            <h3>Cheryl</h3>

            <p>
            A HUGE thank you to you and the Remata team for getting this out for us so quickly. We appreciate it SO MUCH!
            </p>
          </div>
        </div>


        <div>
          <div className="myCarousel1">
            <h3>Sebastian</h3>

            <p>
            Thank you for your exemplary product ,which was produced with a minimum of tears and hassle. I am very happy with the Brochure!
            </p>
          </div>
        </div>


        <div>
          <div className="myCarousel1">
            <h3>Rose</h3>

            <p>
            I just wanted to let you know that my client sent me a message saying “I think the calendars are stunning, the best we have ever done, the printing is also fantastic”
            </p>
          </div>
        </div>


        <div>
          <div className="myCarousel1">
            <h3>Wandile</h3>

            <p>
            As always you guys never disappoint, beautiful work, I will always recommend you guys as you do quality printing at reasonable prices.
            </p>
          </div>
        </div>

        <div>
          <div className="myCarousel1">
            <h3>Dedre</h3>

            <p>
            I just want to say thank you very must for the excellent service and meeting these unbelievable deadlines
            </p>
          </div>
        </div>

        <div>
          <div className="myCarousel1">
            <h3>Leigh-Anne</h3>

            <p>
            We’re absolutely thrilled with all boxes (colour accuracy is fantastic! Best we’ve had with a printer to date!) 
            </p>
          </div>
        </div>



      </Carousel>
      </div>
    );
  }

}
import Layout from '../components/layout'
import './Blog.scss'

const SaddleStitching = (props) => {
    return (
      <Layout {...props} className="blog">
        <div className='main-content'>
            <h1 className='top-fix'><strong>What is Saddle Stitching?</strong></h1> {/* G: Fix for top banner */}    
            <p>Saddle stitching is a versatile and commonly used bookbinding technique that involves binding pages together by stapling them along the fold in 
                the center spine. A method widely used for magazines, booklets, brochures, calendars, etc.</p> 
                <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\SaddleStitch.jpg' alt='Saddle Stitching '></img><br />
            <h2>Advantages of Saddle Stitching:</h2>
            <h3>Cost-effective</h3>
            <p>Saddle stitching is a more cost-effective binding method compared to other alternatives like perfect binding or wire binding. This method is run 
                on a gangline where sections are placed on top of each other, then stapled and trimmed on 3 sides all in one run.</p>
            <h3>Quicker Turnaround Time</h3>
            <p>With saddle stitching, you can achieve a quicker turnaround time for your printed materials. The process is relatively simple compared to other 
                binding methods, allowing for faster production of your booklets or brochures.</p>
            <h3>Easy Reading</h3>
            <p>One of the major advantages of saddle stitching is that the finished product lies flat when opened. This makes it easier for readers to flip 
                through the pages and ensures a seamless reading experience. Whether it's a magazine or a booklet, saddle-stitched materials offer excellent 
                readability.</p>
            <h3>Allows for Full Spread Images</h3>
            <p>Saddle stitching is ideal for publications that require full-spread images or designs. The binding method allows the pages to lay flat, making 
                it possible to showcase large visual elements across the entire spread. This is particularly beneficial for art books, photography collections, 
                or brochures with impactful visuals as perfect-bound books glue in the spine and do not lie flat. Be careful though of detail lying on the folds 
                as the folding process does have tolerances and there can still be movement that can make both sides of the page look misaligned.</p>
            <h3>Ideal for Booklets and Brochures</h3>
            <p>Saddle stitching is particularly well-suited for booklets and brochures with a lower page count. It provides a professional and compact finish, 
                making it an ideal choice for promotional materials, event programs, instruction manuals, and more.</p>
            <h2>Disadvantages of Saddle Stitching:</h2>
            <h3>Limited Page Count</h3>
            <p>The number of pages that can be saddle stitched depends on the thickness of the paper used. As a general guideline, saddle stitching can 
                accommodate up to 84 pages, though this varies radically depending on the paper weight.</p>
            <p>The more pages you add the more a term called ‘creep’ happens. This term explains how the outer pages become ‘shorter’ due to the ‘bowing’ effect 
                of each section added into each other. The staples can also only staple to a certain depth to close properly; so these factors all play a role in 
                how many pages you can comfortably add in your print project.</p>
                <img src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\SaddleStitchCut.jpg' alt='Saddle Stitching | Cut'></img><br /><br />
            <p>If your project requires a large page count, saddle stitching may not be the most suitable binding method, and alternatives like perfect binding 
                or spiral binding should be considered.</p> 
            <h3>Weaker Binding</h3>   
            <p>Compared to other binding methods, saddle stitching may result in a slightly weaker binding. Because the pages are held together by staples, there 
                is a risk of the staples becoming loose or the pages coming apart over time, especially with heavy use. However, for regular reading and moderate 
                usage, saddle stitching provides sufficient durability.</p>
            <h3>Inability to Add or Remove Pages</h3>
            <p>Once a booklet or brochure has been saddle stitched, it becomes extremely challenging to add or remove pages without it looking a mess and tacky. 
                It’s therefore crucial to carefully plan and proofread your document before proceeding with saddle stitching.</p>
            <p><strong>For more robust or frequently used printed materials, alternative binding methods like perfect binding or wire/coil-binding may be more suitable.
                To discuss the best binding for your project <a target='_blank' href='https://onlineprintshop.remata.co.za/en-ZA/contact-us'>contact us</a> on 
                <a href='tel:+27118480000'> 011 848 0000</a>.</strong></p>
        </div>
      </Layout>
    )
}
  
export default SaddleStitching
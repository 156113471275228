import Layout from '../components/layout'
import './Blog.scss'

const PaperSizes = (props) => {
    return (
      <Layout {...props} className="blog">
        <div className='main-content'>
            <h1 className='top-fix'><strong>Paper sizes in Print</strong></h1> {/* G: Fix for top banner */}  
            <p>After thinking that you need to produce a printed piece the very first consideration should be “what size?” Depending on the country you live in, 
                there are standard sizes that all follow and there’s a very good reason behind it.</p>   
            <h2>Paper Sizes in South Africa</h2>
            <p>In South Africa we use the “A Series” of paper sizes which is very different to American sizes. The foundation of the sizes is that from the biggest 
                size each next size down is halve of the original. That means that an A1 is half an A0, and an A2 is half an A1 and so on. Paper bigger than A0 
                keep the same proportions and a number is added to the front of the letter, for example 2A0 is twice as big as an A0 sheet. The only difference is 
                a DL which is 1/3 of an A4. </p>
            <h3>Paper Sizes:</h3>
            <p>A0: 1189 x 841mm<br />
            A1: 841 x 594mm<br />
            A2: 594 x 420mm<br />
            A3: 420 x 297mm<br />
            A4: 297 x 210mm<br />
            A5: 210 x 148mm<br />
            A6: 148 x 105mm
            </p>
            <img src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\PaperSizeComparison.jpg' alt='Paper Sizes | South African Paper Sizes '></img><br /><br />
            <p>If you take an A1 sheet and fold it once in half to an A2, and then in half again, you will have 4 x A3 leaves.  A leaf has a page on either side 
                so this makes it 8 x A3 pages. 4 on one side of the sheet and 4 backing it on the other. And when folding once more, you can get 8 x A4 leaves 
                (16 x A4 pages) out of an A1 sheet, maximizing the paper sheet size to its full potential. In South Africa we stick largely to A1 print area 
                size format as being the biggest end size in print (like posters), providing the best usage of the sheet size.</p>
            <h2>Why are paper sizes important?</h2>
            <p>Offset, and even, digital printing works on paper sizes that optimize these standard sizes using less waste and giving you more print for your rand. 
                This is important especially in longer run jobs where paper plays a significant role in the cost. Paper can cost between 40 to 60 % of the end cost 
                so while a custom size may look funky and unusual, you are really paying for the waste that will be simply thrown away and your publication will 
                be smaller than normal.</p>
            <p>Even envelopes and business cards follow this thinking of standard sizes. If you are wanting to produce a custom sized greeting card, they won’t 
                fit standard envelopes meaning you have to customize a print run of envelope adding to the cost.</p>
            <p>Focus on your message and design to make that message stand out. This is better than putting your focus all on a custom size just because you like 
                the look. That doesn’t mean you should never do custom sizes. Just count the cost and be sure it’s a price you are willing to pay.</p>
            <p><strong>Paper is Print and we can help you understand the sizes or papers you require for your next design project. So, 
                <a target='_blank' href='https://onlineprintshop.remata.co.za/en-ZA/contact-us'>contact us</a> a call on <a href='tel:+27118480000'>011 848 0000</a></strong></p>
        </div>
      </Layout>
    )
}
  
export default PaperSizes
import Layout from '../components/layout'
import './Blog.scss'

const PagesLeavesAndPageCounts = (props) => {
    return (
      <Layout {...props} className="blog">
        <div className='main-content'>
            <h1 className='top-fix'><strong>Pages, Leaves & Page Counts in Printing</strong></h1> {/* G: Fix for top banner */}    
            <p>Whether you're involved in any kind of printing that involves more than 4 pages you will be looking to add folios. Whether its book publishing or 
              magazine production, understanding the intricacies of numbers in print in important. We will try make it as simple as possible so that you never have 
              to make the mistakes of others.</p> 
            <h2>Understanding Pages and Leaves</h2>
            <h3>What is a Page?</h3>
            <p>A page is a single side of a leaf, typically containing text, images, or a combination of both. It is the basic unit of content in a printed 
              publication.</p>
            <h3>What is a Leaf?</h3>
            <p>In printing terminology, a leaf consists of two pages printed back-to-back. It’s the sheet carrying a page on either side.</p>
            <img src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\PageVSLeaf.jpg' alt='Pages in Printing | What is a Leaf'></img><br />
            <p>Understanding what a leaf and page is in printing directly affects the page count of a printed publication. Many times Printers are asked to quote 
              on leaves only to find out later the client meant to say pages.</p>
            <h3>Having a clear understanding of pages and leaves is essential for several reasons:</h3>
            <h4>1. Accurate Print Estimates</h4>
            <p>By knowing the distinction between pages and leaves, printers can accurately estimate the paper required for a project. This ensures that clients 
              receive accurate quotes, preventing any surprises or unexpected costs down the line.</p>
            <h4>2. Efficient Printing Process</h4>
            <p>It further streamlines the printing process, allowing printers to organize the job efficiently. It helps in determining the correct order of pages, 
              ensuring that they are printed and assembled in the correct sequence.</p>
            <h4>3. Minimizes Wastage</h4>
            <p>A key factor in printing is having the correct number of pages (page count) to fill a printer’s section (sheet) so no unwanted blank white pages 
              are in the publication that you end up paying for and looks unsightly.</p>
            <h2>Printers Page Count? </h2>
            <h3>Multiples of Two or Four</h3>
            <p>Litho Printing jobs that have multiple pages, are often planned in multiples of four due to the way books are folded and bound. This means that 
              the page count will start at  4 moving on to 8, then 12, then 16, and so on. Commonly called an “even” page count. Binding methods commonly used 
              in the printing industry, such as saddle stitching and perfect binding, require pages to be added in groups of four. This applied to Perfect Bound, 
              Saddle Stitched and Casebound books. Wire or Coil bound books only require groups of 2 as well as low run quantity  jobs printed on a Digital Press.</p>
              <img src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\PageImpo.jpg' alt='Pages in Printing | Saddle Stitched and Trimmed'></img><br /><br />
            <p>An example of an 8-page document printed 4 pages on the front of the sheet and 4 pages on the back. When folded, put together, saddle stitched and 
              trimmed, you have an 8-page brochure.</p>
            <h3>Adding Covers, Index Pages or intentional blank pages</h3>
            <p>In addition to the main content or inside pages, a printed publication may include covers or other pages like index pages. These additional pages must 
              be considered when determining the total page count of a project. So, if for example your inside numbered pages are 144 pages but before these pages 
              you have also added 4 unnumbered index pages, the count is now 148 pages. In the same manner, should the Cover be on a different paper thickness 
              that should be counted separately as a 4 pager (Outside Front, Outside Back, Inside Front, Inside Back). Should The Cover be on exactly the same 
              stock then the Cover 4 pages get added to the inside count.</p>
            <h3>Here is a handy Printers Page Count video for you to watch:</h3>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/Y634CGg6RTs?si=To3vPs-acPbX7Jod" title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen></iframe><br /><br />
            <p><strong>So the next time you embark on a printing project, be sure to consider the pages and leaves to achieve the desired outcome!<br />
            <a target='_blank' href='https://onlineprintshop.remata.co.za/en-ZA/contact-us'>Contact us</a> if you have any questions: Remata - <a href='tel:+27118480000'>011 848 0000</a></strong></p>
        </div>
      </Layout>
    )
}
  
export default PagesLeavesAndPageCounts
import Layout from '../components/layout'
import './Blog.scss'

const BlackInPrint = (props) => {
    return (
      <Layout {...props} className="blog">
        <div className='main-content'>
            <h1 className='top-fix'><strong>Using Black Ink in text and backgrounds in Printing</strong></h1> {/* G: Fix for top banner */}
            <p>Black is used on every page of almost every printed item you find; whether in the type/text, graphics or a background colour. So, what’s the 
              deal with Black</p>
            <h2>Black used in body text must be…. Black! </h2>
            <p>In printing, Cyan, Magenta, Yellow and Black (CMYK)  is used to create the millions of colours you see in the final print product. However when 
              there is black body text, it must always be 100% black with no other colours mixed in.  It will produce a crisp clear print. To register 4 colour 
              black text across an entire sheet is a huge challenge. One side may well be in register and the other side of the sheet will show the other colours 
              peeping through making it out of register and visually unappealing. So, the rule is always that body text <strong>MUST</strong> always be 100%  black only. To avoid 
              giving your printer a heart attack NEVER place scanned copy in your design. Rather re-type the document correctly!</p>
              <img src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\CMYKText.jpg' alt='Black Ink | Black Text Printing'></img><br />
            <p><strong><em>When black body type is made up of all 4 colours (CMYK) you will struggle to get a clear printed image across the full printed sheet.</em></strong></p>  
            <h2>So when can I make my black out of CMYK?</h2>
            <p>If you want to design using some large areas or even a background of solid black, it is essential to put colour behind the black. This is normally 
              referred to as a ‘rich black’. This is because large expanses of just black only can look very ‘grey’ and ‘see through’ making it a horrible colour to 
              look at. By placing percentages of other (CMY) colours behind the 100% black, the black has the other percentages of colour to compensate, fill in and 
              make the black a rich nice solid colour. Never make the other (CMY) colours 100% as that will make the ink ‘pick’ off and be too tacky to dry.</p>
            <p><strong>The normal percentage colour breakdown which we recommend for a rich black is: <em>Black 100%, Magenta 40%, Yellow 20% and Cyan 60%</em></strong></p>
            <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\BlackVSRichBlack.jpg' alt='Black Ink Printing| Rich vs Standard'></img><br />
            <p><strong>A rich black versus a standard solid black only background</strong></p>
            <p><a target='_blank' href='https://onlineprintshop.remata.co.za/en-ZA/contact-us'>Contact us</a> if you have any questions or need printing done… we are 
            always here to help! <a href='tel:+27118480000'>011 848 0000</a></p>
        </div>
      </Layout>
    )
}
  
export default BlackInPrint